/* global axios */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles/form.scss';

/**
 * Functional component to handle the survey results
 * @param {Array} answers The given answers
 * @return {Component} React component
 */
function Form({answers}) {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [consent, setConsent] = useState(false);
    const [validationFails, setValidationFails] = useState({});
    const [mailStatus, setMailStatus] = useState('waiting');

    /**
     * Sends an ajax request to deliver the mails
     */
    function sendMail() {
        if (consent) {
            setMailStatus('pending');
            axios.post('/sendMail', {
                name: name,
                company: company,
                mail: mail,
                phone: phone,
                answers: answers,
            }).then((success) => {
                setMailStatus('submitted');
            }).catch((error) => {
                setValidationFails(error.response.data.errors);
                setMailStatus('error');
            });
        }
    }

    /**
     * @return {String} The Mail status
     */
    function displayMailStatus() {
        switch (mailStatus) {
        case 'pending':
            return 'Der Mail Versand wird vorbereitet.';
        case 'submitted':
            return 'Die Mail wurde versendet.';
        case 'error':
            return 'Es gab einen Fehler beim Versenden der Mail.';
        }
    }

    /**
     * Returns the error message
     * @param {String} name The input name
     * @return {String} The error message
     */
    function showErrorMsg(name) {
        if (typeof validationFails[name] !== 'undefined') {
            return validationFails[name][0];
        }
        return '';
    }

    return (
        <>
            <div className="row">
                <div className="col-2"></div>
                <div className="form-group col-4">
                    <input
                        className={
                            `go-input${(validationFails.name) ? ' error' : ''}`
                        }
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Vor-/Nachname*"
                    />
                    <span className="error text">{showErrorMsg('name')}</span>
                </div>
                <div className="form-group col-4">
                    <input
                        className={
                            `go-input${
                                (validationFails.company) ? ' error' : ''
                            }`
                        }
                        type="text"
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Unternehmen*"
                    />
                    <span
                        className="error text"
                    >{showErrorMsg('company')}</span>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="row">
                <div className="col-2"></div>
                <div className="form-group col-4">
                    <input
                        className={
                            `go-input${(validationFails.mail) ? ' error' : ''}`
                        }
                        type="text"
                        onChange={(e) => setMail(e.target.value)}
                        placeholder="E-Mail*"
                    />
                    <span className="error text">{showErrorMsg('mail')}</span>
                </div>
                <div className="form-group col-4">
                    <input
                        className={
                            `go-input${(validationFails.phone) ? ' error' : ''}`
                        }
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Telefonnummer"
                    />
                    <span className="error text">{showErrorMsg('phone')}</span>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8 responsive-text">
                    <span className="info-text fs-12">Die mit * markierten Felder sind Pflichtfelder</span>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="row consent-label">
                <div className="col-2"></div>
                <div className="form-group col-8">
                    <div className="checkbox-container text-center go-container">
                        <input
                            id={'consent'}
                            type="checkbox"
                            name={'consent'}
                            onChange={() => setConsent(!consent)}
                            className="privacyConsent btn-block mt-1"
                        />
                        <label
                            htmlFor={'consent'}
                            className="checkbox ml-0 mr-2"
                        >
                        </label>
                        {/* eslint-disable-next-line max-len */}
                        <label htmlFor={'consent'} className="info-text checkboxText fs-12">Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Deiner Anfrage gelöscht. Hinweis: Du kannst die Einwilligung jederzeit für die Zukunft per E-Mail an <a className="custom-link" href="mailto:info@1a-digital.com">info@1a-digital.com</a> widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten sind in unserer Datenschutzerklärung zu finden.</label>
                    </div>
                    <label
                        htmlFor={'consent'}
                        className="description-text text-left pt-3"
                    />
                </div>
                <div className="col-2"></div>
            </div>
            <div className="row justify-content-center">
                <button
                    className={
                        `go-button animated${(!consent) ? ' disabled' : ''}`
                    }
                    type="button"
                    onClick={() => sendMail()}
                    disabled={
                        mailStatus == 'pending' || mailStatus == 'submitted'
                    }
                >ABSENDEN</button>
            </div>
            <div className="row justify-content-center mb-3 mail-info">
                <span>{displayMailStatus()}</span>
            </div>
        </>
    );
}

Form.propTypes = {
    answers: PropTypes.array,
};

export default Form;
