import React from 'react';
import ProtTypes from 'prop-types';
import Headline from './Headline';
import Form from './Form';

/**
 * Functional component to display the survey result
 * @param {Boolean} type Determines the type of the result
 * @param {Boolean} show Determines if the result should be shown
 * @return {Component} React component
 */
function Result({type, show, answers}) {
    return (show) ? (
        <div className="result-container">
            <Headline>
                {
                    (type) ?
                        <>
                            Dein Unternehmen ist <span className="check">FÖRDERFÄHIG</span>
                        </> :
                        <>
                            Dein Unternehmen ist <span className="cross">NICHT FÖRDERFÄHIG</span>
                        </>
                }
            </Headline>

            <div className="row">
                <div className="col-1"></div>
                <div className="col-10 responsive-text">
                    <p className="text-center fs-15 mb-2 info-text">
                        {
                            (type) ?
                                <>
                                    {/* eslint-disable-next-line max-len */}
                                    Dein Unternehmen erfüllt alle Voraussetzungen für eine go-digital-Förderung. Gerne informieren wir Dich in einem persönlichen Gespräch über die Möglichkeiten per Chat oder hinterlasse uns Deine Kontaktdaten und wir melden uns bei Dir!
                                </> :
                                <>
                                    {/* eslint-disable-next-line max-len */}
                                    Leider erfüllt Dein Unternehmen nicht alle Voraussetzungen für eine go-digital-Förderung. Gerne beraten wir Dich jedoch auch zu anderen möglichen Förder- oder Umsetzungsmaßnahmen per Chat oder hinterlass uns Deine Kontaktdaten und wir melden uns bei Dir!
                                </>
                        }
                    </p>
                </div>
                <div className="col-1"></div>
            </div>

            <Form answers={answers} />
        </div>
    ) : '';
}

Result.propTypes = {
    type: ProtTypes.bool,
    show: ProtTypes.bool,
    answers: ProtTypes.array,
};

export default Result;
