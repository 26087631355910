import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Headline from './Headline';
import {v4} from 'uuid';

import '../styles/question.scss';

/**
 * Functional component to display a yes or no question
 * @prop {String} headline The headline
 * @prop {Boolean} answer The answer
 * @prop {Function} setAnswer Pass answer to parent component
 * @prop {Boolean} show If true displays the component
 * @prop {String} force Contains 'show', 'hide' or nothing
 * @return {Component} React component
 */
function Question({headline, answer, setAnswer, show, force}) {
    const ids = [v4(), v4()];
    const uniqueName = useState(v4());

    /**
     * Determines if the question should be displayed
     * @return {Boolean}
     */
    function shouldShow() {
        if (force === 'show') {
            return true;
        } else if (force === 'hide') {
            return false;
        } else {
            return show;
        }
    }

    return (shouldShow()) ? (
        <div className="question-container">
            <Headline question={false}>DEINE FÖRDERFÄHIGKEIT</Headline>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8 responsive-text">
                    {/* eslint-disable-next-line max-len */}
                    <p className="text-center fs-20 info-text">Du willst mit der go-digital Förderung des BMWI bei der Umsetzung eures Digitalisierungsprojektes bis zu 16.500 EUR Fördersumme erhalten? Beantworte kurz 3 Fragen und komm der Förderung einen Schritt näher!</p>
                </div>
                <div className="col-2"></div>
            </div>

            <div>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 responsive-text">
                        <Headline question={true}><>{headline}</></Headline>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="row">
                    {['JA', 'NEIN'].map((label, index) =>
                        <div className="col-6" key={index}>
                            <ul className="radioList">
                                <li className="radioList text-center">
                                    <input
                                        id={ids[index]}
                                        type="radio"
                                        name={uniqueName}
                                        value={!index}
                                        onClick={(e) => setAnswer(e.target.value)}
                                        className="quizQuestion btn-block mt-1"
                                    />
                                    <label
                                        htmlFor={ids[index]}
                                        className="radiolabel ml-2"
                                    >
                                        <p className="radioText">{label}</p>
                                    </label>
                                </li>
                            </ul>
                            <label
                                htmlFor={ids[index]}
                                className="description-text text-left pt-3"
                            />
                        </div>,
                    )}
                </div>
            </div>
        </div>
    ) : '';
}

Question.propTypes = {
    headline: PropTypes.string,
    answer: PropTypes.bool,
    setAnswer: PropTypes.func,
    show: PropTypes.bool,
    force: PropTypes.string,
};

export default Question;
