import React, {useState} from 'react';
import Question from './components/Question';
import Result from './components/Result';
import jQuery from 'jquery';

import './styles/home.scss';

/**
 * Functional component which contains the go digital check
 * @return {Component} React component
 */
function Home() {
    const [answers, setAnswers] = useState([]);

    /**
     * Set the answer at the given index
     * @param {Boolean} answer The answer
     * @param {Number} index The answers index
     */
    function setAnswer(answer, index) {
        const isNowBool = answer === 'true';

        if (!answers.length) {
            setAnswers([isNowBool]);
        } else {
            const copy = [...answers];
            copy.splice(index, 1, isNowBool);

            setAnswers(copy);
        }

        jQuery('.carousel').carousel('next').carousel('pause');
    }

    /**
     * Contains logic to show the Result
     * @return {Boolean}
     */
    function showResult() {
        return answers.length === 3;
    }

    return (
        <div className="container-fluid">
            <div className="row content justify-content-center">
                <div className="mainContent">
                    <div id="questionCarouselIndicators" className="carousel slide" data-interval="false" data-wrap="false">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="slideNumber"><span className="numberText">1</span></div>
                                <Question
                                    // eslint-disable-next-line max-len
                                    headline={'Beschäftigst Du weniger als 100 Mitarbeitende in Deinem Unternehmen?'}
                                    answer={answers[0]}
                                    setAnswer={(answer) => setAnswer(answer, 0)}
                                    show={answers.length >= 0}
                                />
                            </div>
                            <div className="carousel-item">
                                <div className="slideNumber">2</div>
                                <Question
                                    // eslint-disable-next-line max-len
                                    headline={'Hast Du innerhalb der letzten drei Jahre weniger als 200.000€ staatliche Förderung erhalten?'}
                                    answer={answers[1]}
                                    setAnswer={(answer) => setAnswer(answer, 1)}
                                    show={answers.length >= 1}
                                />
                            </div>
                            <div className="carousel-item">
                                <div className="slideNumber">3</div>
                                <Question
                                    // eslint-disable-next-line max-len
                                    headline={'Erwirtschaftet Dein Unternehmen jährlich weniger als 20 Mio. € Umsatz bzw. weist weniger als 20 Mio. € Bilanzsumme aus?'}
                                    answer={answers[2]}
                                    setAnswer={(answer) => setAnswer(answer, 2)}
                                    show={answers.length >= 2}
                                />
                            </div>
                            <div className="carousel-item">
                                <div className="slideNumber">4</div>
                                <Result
                                    type={answers.every((x) => x === true)}
                                    show={showResult()}
                                    answers={answers}
                                />
                            </div>
                        </div>
                        <ol className="carousel-indicators">
                            <li data-target="#questionCarouselIndicators" className="carousel-indicator active"></li>
                            <li data-target="#questionCarouselIndicators" className="carousel-indicator"></li>
                            <li data-target="#questionCarouselIndicators" className="carousel-indicator"></li>
                            <li data-target="#questionCarouselIndicators" className="carousel-indicator"></li>
                        </ol>
                    </div>
                    <p className="footer-links text-right mb-0">
                        <a href="https://1a-digital.com/impressum/" className="footer-link custom-link" target="_blank" rel="noopener noreferrer">
                            Impressum
                        </a>
                        |
                        <a href="https://1a-digital.com/datenschutz/" className="footer-link custom-link" target="_blank" rel="noopener noreferrer">
                            Datenschutz
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Home;
