import React from 'react';
import PropTypes from 'prop-types';
import '../styles/headline.scss';

/**
 * Functional component to display the headlines
 * @prop {Object|String} children The headline
 * @param {Boolean} question Belongs headline to a question
 * @return {Component} React component
 */
function Headline({children, question}) {
    return (
        <div>
            <div className="row text-center mb-4">
                {/* eslint-disable-next-line max-len */}
                <div className={`${question ? 'questionHeadline ' : 'headline '}col-12 d-table`}>
                    <div className="d-table-cell sameHeight">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

Headline.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    question: PropTypes.bool,
};

export default Headline;
